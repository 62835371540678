<template>
  <b-card>
    <b-form>
      <b-row>
        <b-col
          cols="12"
          md="12"
        >
          <b-alert
            v-model="showMessage"
            dismissible
            :variant="messageType"
          >
            <div class="alert-body">
              <feather-icon
                class="mr-25"
                icon="InfoIcon"
              />
              <span
                class="ml-25"
                v-html="message"
              />
            </div>
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <div class="d-flex align-items-center mb-2">
            <feather-icon
              icon="SettingsIcon"
              size="18"
            />
            <h4 class="mb-0 ml-75">
              إعدادات التقارير
            </h4>
          </div>
        </b-col>
        <b-col cols="12">
          <div class="d-flex align-items-center mb-2">
            <b-form-group
              label="رقم التسلسل آلي"
              :label-for="`auto_report_reference`"
            >
              <b-form-checkbox
                v-model="auto_report_reference"
                class="mr-0 mt-50"
                :name="`auto_report_reference`"
                switch
                inline
              />
            </b-form-group>
          </div>
        </b-col>
        <b-col
          v-if="!auto_report_reference"
          cols="12"
        >
          <div
            v-for="(part, index) in evaluationParts"
            :key="index"
          >
            <id-part
              :part="part"
              @update:part="updatePart($event, index)"
              @remove="removePart(index)"
            />
          </div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="addPart"
          >
            +
          </b-button>
          <div class="mb-2">
            <h5>رقم التسلسل:</h5>
            <p>{{ previewValues }}</p>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click="updateSettings"
          >
            حفظ التغييرات
          </b-button>
          <!-- <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              @click.prevent="resetForm"
            >
              إعادة ضبط
            </b-button> -->
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BFormCheckbox,
  BFormRadioGroup,
  BFormRadio,
  BFormFile, BForm, BFormGroup, BFormInput, BAlert, BCardText, BMedia, BMediaAside, BMediaBody, BLink,

} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { alertMessageMixin } from '@core/mixins/ui/feedback';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import IdPart from './IdPart.vue'; // Ensure you have this component created

export default {
  components: {
    IdPart,
    BButton,
    BRow,
    BCol,
    BCard,
    BFormCheckbox,
    BFormRadioGroup,
    BFormRadio,
    BInputGroup,
    BFormFile,
    BForm,
    BFormGroup,
    BFormInput,
    BAlert,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
  },
  directives: {
    Ripple,
  },

  mixins: [alertMessageMixin],
  props: {
    settingsData: {
      type: Object,
    },
  },
  data() {
    return {
      auto_report_reference: !!(this.settingsData && (!this.settingsData.hasOwnProperty('report_reference') || this.settingsData.report_reference === null)),
      evaluationParts: [{ type: { value: 'text', label: 'نص ثابت' }, value: '' }], // Default part
    };
  },
  computed: {
    previewValues() {
      // Concatenate all part values into one string
      let preview = '';

      // Use settingsData.report_reference as the default value if it exists
      if (this.settingsData.report_reference) {
        preview += this.settingsData.report_reference;
      }

      this.evaluationParts.forEach((part) => {
        if (part.type.value === 'text' || part.type.value === 'number') {
          preview += part.value;
        } else if (part.type.value === 'date') {
          const currentDate = new Date();
          if (part.value.value === 'year') {
            preview += currentDate.getFullYear();
          } else if (part.value.value === 'month') {
            preview += currentDate.getMonth() + 1;
          } else {
            preview += currentDate.getDate();
          }
        } else {
          preview += part.value.value;
        }
      });

      return preview;
    },
  },
  methods: {
    addPart() {
      // Add a default part, you can change the default settings
      this.evaluationParts.push({ type: { value: 'text', label: 'نص ثابت' }, value: '' });
    },

    updatePart(updatedPart, index) {
      // Update the specific part at the given index
      this.$set(this.evaluationParts, index, updatedPart);
    },

    removePart(index) {
      // Remove the part at the given index
      this.evaluationParts.splice(index, 1);
    },
    resetForm() {
      // Reset the form to initial state
      this.evaluationParts = [{ type: { value: 'text', label: 'نص ثابت' }, value: '' }];
    },
    updateSettings() {
      this.resetMessage();
      if (this.auto_report_reference) {
        this.settingsData.report_reference = null;
      } else {
        this.settingsData.report_reference = this.previewValues;
      }
      this.$http.put(`/api/company/${this.settingsData.company_id}/report-settings`,
        this.settingsData).then((response) => {
        this.$toast.success(response.data.message);
        // router.push("/user").catch(() => {});
      })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 422) {
              this.$toast.error(err.response.data.message);
              const { message, errors } = err.response.data;
              if (errors) {
                const errorMessage = {
                  message: '',
                  type: 'danger',
                };
                Object.values(errors).forEach((error) => {
                  errorMessage.message += `${error[0]}<br>`;
                });
                this.displayMessage(errorMessage);
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  text: message,
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                },
              });
            } else {
              this.$toast.error(err.response.data.error);
            }
          }
          console.log(err);
        });
    },
  },

};
</script>
