<template>
  <div class="id-part">
    <b-form-group>
      <label>اختر نوع الجزء:</label>
      <v-select
        v-model="part.type"
        :options="partTypes"
      />
    </b-form-group>

    <div v-if="part.type.value === 'text'">
      <b-form-group label="أدخل نصًا ثابتًا:">
        <b-form-input v-model="part.value" />
      </b-form-group>
    </div>

    <div v-if="part.type.value === 'number'">
      <b-form-group label="رقم تسلسلي:">
        <b-form-input
          v-model="part.value"
          type="number"
          placeholder="قيمة التسلسل"
        />
      </b-form-group>
    </div>

    <div v-if="part.type.value === 'date'">
      <b-form-group label="اختر تنسيق التاريخ:">
        <v-select
          v-model="part.value"
          :options="dateFormats"
        />
      </b-form-group>
    </div>
    <div v-if="part.type.value === 'separator'">
      <b-form-group label="اختر  فاصلة:">
        <v-select
          v-model="part.value"
          :options="separatorFormats"
        />
      </b-form-group>
    </div>

    <b-button
      variant="danger"
      @click="$emit('remove')"
    >
      حذف
    </b-button>
  </div>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput,
} from 'bootstrap-vue';
import vSelect from 'vue-select';

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
  },
  props: {
    part: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      partTypes: [
        { value: 'text', label: 'نص ثابت' },
        { value: 'number', label: 'رقم تسلسلي' },
        { value: 'date', label: 'تاريخ' },
        { value: 'separator', label: 'فاصلة' },
      ],
      dateFormats: [
        { value: 'year', label: 'سنة' },
        { value: 'month', label: 'شهر' },
        { value: 'day', label: 'يوم' },
      ],
      separatorFormats: [
        { value: '/', label: '/' },
        { value: '-', label: '-' },
      ],
    };
  },
};
</script>

<style scoped>
.id-part {
    margin-bottom: 20px;
}
</style>
