import axios from '@axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    addCompanyLogo(ctx, { id, data }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/company-logo/${id}`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateStampImage(ctx, { id, stampData }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/company-stamp/${id}`, stampData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchCities(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/city/list')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchDistricts(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/district/city/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchBanks(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/bank/list`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
