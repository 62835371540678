<template>
  <b-card>
    <b-form class="mt-2">
      <b-row>
        <b-col
          cols="12"
          md="12"
        >
          <b-alert
            v-model="showMessage"
            dismissible
            :variant="messageType"
          >
            <div class="alert-body">
              <feather-icon
                class="mr-25"
                icon="InfoIcon"
              />
              <span
                class="ml-25"
                v-html="message"
              />
            </div>
          </b-alert>
        </b-col>
      </b-row>
      <!-- media -->
      <b-media no-body>
        <b-media-aside>
          <b-link>
            <b-img
              v-if="optionsLocal.logo"
              ref="previewEl"
              rounded
              :src="optionsLocal.logo"
              height="80"
            />
            <b-img
              v-if="!optionsLocal.logo"
              ref="previewEl"
              rounded
              src="/logo-placeholder.png"
              height="80"
            />
          </b-link>
          <!--/ avatar -->
        </b-media-aside>

        <b-media-body class="mt-75 ml-75">
          <!-- upload button -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="mb-75 mr-75"
            @click="$refs.refInputEl.$el.click()"
          >
            اختر شعار
          </b-button>
          <b-form-file
            ref="refInputEl"
            v-model="profileFile"
            name="image"
            accept="image/*"
            :hidden="true"
            plain
            @input="inputImageRenderer"
          />
          <!--/ upload button -->

          <!-- reset -->
          <b-button
            v-if="profileFile"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            size="sm"
            class="mb-75 mr-75"
            @click="updateCompanyLogo"
          >
            تحميل
          </b-button>
          <!--/ reset -->
          <b-card-text>
            الصيغة المسموح بها JPG أو GIF أو PNG.بحد أقصى 2
            ميجابايت
          </b-card-text>
        </b-media-body>
      </b-media>
      <!--/ media -->

      <!-- stamp Image -->
      <b-media no-body>
        <b-media-aside>
          <b-link>
            <!-- Use a v-if to check if optionsLocal.stamp exists -->
            <b-img
              v-if="optionsLocal && optionsLocal.stamp"
              ref="stampImagePreview"
              rounded
              :src="optionsLocal.stamp"
              height="80"
            />
            <!-- If optionsLocal.stamp doesn't exist or is null, show a placeholder image -->
            <b-img
              v-else
              ref="stampImagePreview"
              rounded
              src="/stamp-placeholder.png"
              height="80"
            />
          </b-link>
        </b-media-aside>

        <b-media-body class="mt-75 ml-75">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="mb-75 mr-75"
            @click="$refs.stampImageInput.$el.click()"
          >
            اختر صورة الختم
          </b-button>
          <b-form-file
            ref="stampImageInput"
            v-model="stampFile"
            name="image"
            accept="image/*"
            :hidden="true"
            plain
            @input="stampImageRenderer"
          />
          <!-- reset -->
          <b-button
            v-if="stampFile"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            size="sm"
            class="mb-75 mr-75"
            @click="updateStampImage"
          >
            تحميل
          </b-button>
          <!--/ reset -->
          <b-card-text>
            الصيغة المسموح بها: JPG، GIF، أو PNG. الحد الأقصى 2 ميجابايت
          </b-card-text>
        </b-media-body>
      </b-media>
      <!--/ stamp Image -->

      <!-- form -->
      <b-row class="mt-2">
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: name -->
          <b-form-group
            label="إسم الشركة"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="generalData.name"
              plaintext
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: name -->
          <b-form-group
            label="تاريخ إنشاء حساب الشركة"
            label-for="company_date"
          >
            <b-form-input
              id="company_date"
              v-model="generalData.company_date"
              plaintext
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: name -->
          <b-form-group
            label="إسم مدير الشركة"
            label-for="owner_name"
          >
            <b-form-input
              id="owner_name"
              v-model="generalData.owner_name"
              plaintext
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: name -->
          <b-form-group
            label="البريد الإلكتروني"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="generalData.email"
              plaintext
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: license_number -->
          <b-form-group
            label="رقم الترخيص"
            label-for="license-number"
          >
            <b-form-input
              id="license-number"
              v-model="generalData.license_number"
              plaintext
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: membership_number -->
          <b-form-group
            label="رقم العضوية"
            label-for="membership-number"
          >
            <b-form-input
              id="membership-number"
              v-model="generalData.membership_number"
              plaintext
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: commercial_register -->
          <b-form-group
            label="السجل التجاري"
            label-for="commercial-register"
          >
            <b-form-input
              id="commercial-register"
              v-model="generalData.commercial_register"
              plaintext
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: phone -->
          <validation-provider
            #default="validationContext"
            vid="phone"
            name="phone"
            rules="required"
          >
            <b-form-group
              label="رقم الهاتف *"
              label-for="phone"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="phone"
                v-model="generalData.phone"
                type="tel"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
        <validation-provider
          #default="validationContext"
          vid="mobile"
          name="mobile"
          rules="required"
         >
          <b-form-group
            label="رقم الجوال *"
            label-for="mobile"
            :state="getValidationState(validationContext)"
          >
            <b-form-input
              id="mobile"
              v-model="generalData.mobile"
              type="tel"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: mobile -->
          <validation-provider
            #default="validationContext"
            vid="email"
            name="email"
            rules="required|email"
          >
            <b-form-group
              label="البريد الإلكتروني *"
              label-for="email"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="email"
                v-model="generalData.email"
                type="email"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>                
        <b-col cols="12" md="6" lg="6">
          <!-- Field: tax_number -->
          <b-form-group label="الرقم الضريبي" label-for="tax_number">
            <b-form-input id="tax_number" v-model="generalData.tax_number" />
          </b-form-group>
        </b-col>        
        <b-col cols="12" md="6" lg="6">
          <!-- Field: tax_value -->
          <b-form-group label="القيمة المضافة" label-for="tax_value">
            <b-form-input 
              id="tax_value" 
              v-model="generalData.tax_value" 
              type="number" 
              min="0" 
              max="100" 
              @change="validateTaxValue" 
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <hr class="my-2">
        </b-col>
        <b-col cols="12">
          <div class="d-flex align-items-center mb-2">
            <feather-icon
              icon="PackageIcon"
              size="18"
            />
            <h4 class="mb-0 ml-75">
              الإشتراك
            </h4>
          </div>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: commercial_register -->
          <b-form-group
            label="نوع الإشتراك"
            label-for="subscription_type"
          >
            <b-form-input
              id="subscription_type"
              :value="$t(generalData.subscription.type)"
              plaintext
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: commercial_register -->
          <b-form-group
            label="حالة الاشتراك"
            label-for="subscription_status"
          >
            <b-form-input
              id="subscription_status"
              v-model="generalData.subscription.status"
              plaintext
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: commercial_register -->
          <b-form-group
            label="تاريخ بداية الاشتراك"
            label-for="subscription_starts_at"
          >
            <b-form-input
              id="subscription_starts_at"
              v-model="generalData.subscription.starts_at"
              plaintext
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: commercial_register -->
          <b-form-group
            label="تاريخ نهاية الإشتراك"
            label-for="subscription_expires_at"
          >
            <b-form-input
              id="subscription_expires_at"
              v-model="generalData.subscription.expires_at"
              plaintext
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <hr class="my-2">
        </b-col>
        <b-col
          cols="12"
          md="12"
          lg="12"
        >
          <div class="d-flex">
            <feather-icon
              icon="UserIcon"
              size="19"
            />
            <h4 class="mb-0 ml-50">
              العنوان الوطني للشركة
            </h4>
          </div>
        </b-col>
        <b-col cols="12">
          <hr class="my-2">
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: building_number -->
          <b-form-group
            label="رقم المبنى"
            label-for="building_number"
            descrition=""
          >
            <b-form-input
              id="building_number"
              v-model="generalData.address.building_number"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: street -->
          <b-form-group
            label="الشارع"
            label-for="street"
          >
            <b-form-input
              id="street"
              v-model="generalData.address.street"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: city -->
          <b-form-group
            label="المدينة"
            label-for="city"
          >
            <v-select
              v-model="generalData.address.city_id"
              dir="rtl"
              :options="cities"
              :reduce="val => val.value"
              :clearable="false"
              input-id="city"
              @input="cityChanged"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: district_id -->
          <b-form-group
            label="الحي"
            label-for="district_id"
          >
            <v-select
              v-model="generalData.address.district_id"
              dir="rtl"
              :loading="isLoading"
              :options="districts"
              :reduce="val => val.value"
              :disabled="districts.length == 0"
              :clearable="false"
              input-id="district_id"
              @input="districtChanged"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: postcode -->
          <b-form-group
            label="الرمز البريدي"
            label-for="postcode"
          >
            <b-form-input
              id="postcode"
              v-model="generalData.address.postcode"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: additional_number -->
          <b-form-group
            label="الرمز الاضافي"
            label-for="additional_number"
          >
            <b-form-input
              id="additional_number"
              v-model="generalData.address.additional_number"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: short_national_address -->
          <b-form-group
            label="العنوان الوطني المختصر"
            label-for="short_national_address"
          >
            <b-form-input
              id="short_national_address"
              v-model="generalData.address.short_national_address"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <hr class="my-2">
        </b-col>
        <b-col cols="12" md="12" lg="12">
          <div class="d-flex">
            <feather-icon icon="GlobeIcon" size="19" />
            <h4 class="mb-0 ml-50">
              الحسابات البنكية
              <b-button class="btn-icon rounded-circle btn-sm" variant="primary"
                style="width: 14px; height: 14px; padding: 0" size="sm" @click="addBankAccount()">
                <feather-icon icon="PlusIcon" size="10" />
              </b-button>
            </h4>
          </div>
        </b-col>
        <b-col cols="12">
          <hr class="my-2">
        </b-col>
      </b-row>
      <b-row>
        <b-col v-for="(item, i) in generalData.company_accounts" :id="item.id" :key="i" ref="row" cols="12" md="12"
          lg="12">
          <b-row>
            <b-col :cols="12" md="6" lg="6">
              <!-- Field: short_national_address -->
              <b-form-group label=" البنك او المصرف" label-for="company_accounts_name">
                <v-select v-model="generalData.company_accounts[i].bank_id" dir="rtl" :options="banks" :reduce="val => val.value"
              :clearable="false"  />
              </b-form-group>
            </b-col>
            <b-col :cols="12" md="6" lg="6">
              <div style="display: flex; align-items: center;">
                <b-form-group label="رقم الحساب" label-for="company_accounts_rib" style="width: 100%">
                  <b-form-input v-model="generalData.company_accounts[i].rib" />
                </b-form-group>
                <b-button v-if="generalData.company_accounts.length > 0" class="btn-icon" variant="danger" size="sm"
                  style="margin-right: 10px; left: 20px; width: 18px; height: 18px; padding: 0"
                  @click="removeBankAccount(i)">
                  <feather-icon icon="XIcon" size="12" />
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-2 mr-1"
            @click="updateCompany">
            حفظ التغييرات
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormTextarea,
  BFormText,
  BFormInvalidFeedback,
} from 'bootstrap-vue';
import { serialize } from 'object-to-formdata';
import Ripple from 'vue-ripple-directive';
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils';
import { ref, onUnmounted } from '@vue/composition-api';
import store from '@/store';
import { alertMessageMixin } from '@core/mixins/ui/feedback';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import vSelect from 'vue-select';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, alphaNum, validEmail } from '@validations';import accountStore from './accountStore';
import formValidation from '@core/comp-functions/forms/form-validation';
export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormTextarea,
    BFormText,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
  },
  mixins: [alertMessageMixin],
  props: {
    generalData: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      profileFile: null,
      stampFile: null,
      isLoading: false,
      isLoading: false,
      required,
      alphaNum,
      validEmail,
    };
  },
  mounted() {
  },
  methods: {
    validateTaxValue() {
      if (this.generalData.tax_value < 0 || this.generalData.tax_value > 100) {
        this.generalData.tax_value = Math.min(Math.max(this.generalData.tax_value, 0), 100);
      }
    },
    addBankAccount() {
      this.generalData.company_accounts.push({});      
    },
    removeBankAccount(index) {
        this.generalData.company_accounts.splice(index, 1);
      
    },
    updateCompanyLogo() {
      if (this.profileFile !== null) {
        const data = serialize({ image: this.profileFile });
        this.$store
          .dispatch('company/addCompanyLogo', {
            id: this.generalData.id,
            data,
          }, { root: true })
          .then((response) => {
            this.$toast.success(response.data.message);
            // Update the logo path in your local data or perform any other necessary action
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 422) {
                this.$toast.error(err.response.data.message);
                const { message, errors } = err.response.data;
                if (errors) {
                  const errorMessage = {
                    message: '',
                    type: 'danger',
                  };
                  Object.values(errors).forEach((error) => {
                    errorMessage.message += `${error[0]}<br>`;
                  });
                  this.displayMessage(errorMessage);
                }
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    text: message,
                    icon: 'AlertCircleIcon',
                    variant: 'danger',
                  },
                });
              } else {
                this.$toast.error(err.response.data.error);
              }
            }
            console.log(err);
          });
      }
    },
    updateStampImage() {
      if (this.stampFile !== null) {
        const stampData = serialize({ image: this.stampFile });
        this.$store
          .dispatch('company/updateStampImage', {
            id: this.generalData.id,
            stampData,
          }, { root: true })
          .then((response) => {
            this.$toast.success(response.data.message);
            // Update the logo path in your local data or perform any other necessary action
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 422) {
                this.$toast.error(err.response.data.message);
                const { message, errors } = err.response.data;
                if (errors) {
                  const errorMessage = {
                    message: '',
                    type: 'danger',
                  };
                  Object.values(errors).forEach((error) => {
                    errorMessage.message += `${error[0]}<br>`;
                  });
                  this.displayMessage(errorMessage);
                }
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    text: message,
                    icon: 'AlertCircleIcon',
                    variant: 'danger',
                  },
                });
              } else {
                this.$toast.error(err.response.data.error);
              }
            }
            console.log(err);
          });
      }
    },

    updateCompany() {
      this.$http
        .put(`/api/company/${this.generalData.id}`, {
          phone: this.generalData.phone,
          mobile: this.generalData.mobile,
          email: this.generalData.email,
          tax_number: this.generalData.tax_number,
          tax_value: this.generalData.tax_value,
          address: this.generalData.address,
          company_accounts: this.generalData.company_accounts,
        })
        .then((response) => {
          this.$toast.success(response.data.message);
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 422) {
              this.$toast.error(err.response.data.message);
              const { message, errors } = err.response.data;
              if (errors) {
                const errorMessage = {
                  message: '',
                  type: 'danger',
                };
                Object.values(errors).forEach((error) => {
                  errorMessage.message += `${error[0]}<br>`;
                });
                this.displayMessage(errorMessage);
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  text: message,
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                },
              });
            } else {
              this.$toast.error(err.response.data.error);
            }
          }
          console.log(err);
        });
    },
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData));
    },
    cityChanged(city_id) {
      this.fetchDistricts(city_id);
      this.generalData.address.city_id = city_id;
      this.generalData.address.district_id = null;
    },
    districtChanged(district_id) {
      this.generalData.address.district_id = district_id;
    },
    fetchDistricts(city_id) {
      this.isLoading = true;
      this.$store
        .dispatch('company/fetchDistricts', { id: city_id }, { root: true })
        .then((response) => {
          const { data } = response.data;
          this.districts = data.map((district) => ({
            label: district.name_ar,
            value: district.id,
          }));
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
  },
  setup(props) {
    const COMPANY_STORE_MODULE_NAME = 'company';

    // Register
    if (!store.hasModule(COMPANY_STORE_MODULE_NAME)) store.registerModule(COMPANY_STORE_MODULE_NAME, accountStore);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(COMPANY_STORE_MODULE_NAME)) store.unregisterModule(COMPANY_STORE_MODULE_NAME);
    });
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);
    const stampImageInput = ref(null);

    const stampImagePreview = ref(null);

    const { inputImageRenderer: stampImageRenderer } = useInputImageRenderer(stampImageInput, stampImagePreview);
    const cities = ref([]);
    store
      .dispatch('company/fetchCities')
      .then((response) => {
        const { data } = response.data;
        cities.value = data.map((c) => ({
          label: c.name_ar,
          value: c.id,
          ...c,
        }));
      });
    const districts = ref([]);
    store
      .dispatch('company/fetchDistricts', { id: props.generalData.address.city_id })
      .then((response) => {
        const { data } = response.data;
        districts.value = data.map((district) => ({
          label: district.name_ar,
          value: district.id,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
      const banks = ref([]);
    store
      .dispatch('company/fetchBanks')
      .then((response) => {
        const { data: banksData } = response.data;
        banks.value = banksData.map((bank) => ({
          ...bank,
          value: bank.id,
          label: bank.name,
        }));
      });
      const resetAddressData = () => ({
    });

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetAddressData);

    return {
      banks,
      districts,
      cities,
      refInputEl,
      previewEl,
      inputImageRenderer,
      stampImageInput,
      stampImagePreview,
      stampImageRenderer,      
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>
