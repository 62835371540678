<template>
  <b-card>
    <b-form>
      <b-row>
        <b-col
          cols="12"
          md="12"
        >
          <b-alert
            v-model="showMessage"
            dismissible
            :variant="messageType"
          >
            <div class="alert-body">
              <feather-icon
                class="mr-25"
                icon="InfoIcon"
              />
              <span
                class="ml-25"
                v-html="message"
              />
            </div>
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <div class="d-flex align-items-center mb-2">
            <feather-icon
              icon="SettingsIcon"
              size="18"
            />
            <h4 class="mb-0 ml-75">
              إعدادات الخصوصية
            </h4>
          </div>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="إشعار زمن بقاء الطلب الجديد من دون إجراء تحت التقييم"
            label-for="request_without_evaluation"
            label-cols-md="4"
          >
            <b-input-group append="ساعة">
              <b-form-input
                id="request_without_evaluation"
                v-model="settingsData.request_without_evaluation"
                type="number"
                placeholder="زمن بقاء الطلب الجديد من دون إجراء تحت التقييم"
              />
            </b-input-group>
          </b-form-group>
          <b-form-group
            label="إشعار زمن بقاء العقار تحت التقييم من دون إجراء تم التقييم"
            label-for="estate_max_evaluation_duration"
            label-cols-md="4"
          >
            <b-input-group append="ساعة">
              <b-form-input
                id="estate_max_evaluation_duration"
                v-model="settingsData.estate_max_evaluation_duration"
                type="number"
                placeholder="إشعار زمن بقاء العقار تحت التقييم من دون إجراء تم التقييم"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <hr class="my-2">
        </b-col>
        <b-col cols="12">
          <div class="d-flex align-items-center mb-2">
            <feather-icon
              icon="PieChartIcon"
              size="18"
            />
            <h4 class="mb-0 ml-75">
              حصّة المستخدمين
            </h4>
          </div>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="حصّة المدير من كلّ تقييم"
            label-for="manager_share"
            label-cols-md="4"
          >
            <b-input-group append="%">
              <b-form-input
                id="manager_share"
                v-model="settingsData.manager_share"
                type="number"
                placeholder="حصّةالمدير من كلّ تقييم"
              />
            </b-input-group>
          </b-form-group>
          <b-form-group
            label="حصّةالمقيّم من كلّ تقييم"
            label-for="evaluator_share"
            label-cols-md="4"
          >
            <b-input-group append="%">
              <b-form-input
                id="evaluator_share"
                v-model="settingsData.evaluator_share"
                type="number"
                placeholder="حصّةالمقيّم من كلّ تقييم"
              />
            </b-input-group>
          </b-form-group>
          <b-form-group
            label="حصّة المنسّق من كلّ تقييم"
            label-for="coordinator_share"
            label-cols-md="4"
          >
            <b-input-group append="%">
              <b-form-input
                id="coordinator_share"
                v-model="settingsData.coordinator_share"
                type="number"
                placeholder="حصّة المنسّق من كلّ تقييم"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      <!--/ application switch -->
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click="updateSettings"
          >
            حفظ التغييرات
          </b-button>
          <!-- <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="resetForm"
          >
            إعادة ضبط
          </b-button> -->
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BFormFile, BForm, BFormGroup, BFormInput, BAlert, BCardText, BMedia, BMediaAside, BMediaBody, BLink,

} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { alertMessageMixin } from '@core/mixins/ui/feedback';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BFormFile,
    BForm,
    BFormGroup,
    BFormInput,
    BAlert,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
  },
  directives: {
    Ripple,
  },
  mixins: [alertMessageMixin],
  props: {
    settingsData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
    };
  },
  methods: {
    updateSettings() {
      this.resetMessage();
      this.$http.put(`/api/company/${this.settingsData.company_id}/settings`,
        this.settingsData).then((response) => {
        console.log(response);
        this.$toast.success(response.data.message);
        // router.push("/user").catch(() => {});
      })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 422) {
              this.$toast.error(err.response.data.message);
              const { message, errors } = err.response.data;
              if (errors) {
                const errorMessage = {
                  message: '',
                  type: 'danger',
                };
                Object.values(errors).forEach((error) => {
                  errorMessage.message += `${error[0]}<br>`;
                });
                this.displayMessage(errorMessage);
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  text: message,
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                },
              });
            } else {
              this.$toast.error(err.response.data.error);
            }
          }
          console.log(err);
        });
    },
  },
};
</script>
