<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- general tab -->
    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">عام</span>
      </template>

      <account-setting-general
        v-if="options.general"
        :general-data="options.general"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- notification -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="SettingsIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">الإعدادات</span>
      </template>

      <account-setting-notification
        v-if="options.settings"
        :settings-data="options.settings"
      />
    </b-tab>
    <!-- <b-tab>
      <template #title>
        <feather-icon
          icon="SettingsIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">إعدادات التقرير</span>
      </template>

      <report-setting-information
        v-if="options.settings"
        :settings-data="options.settings"
      />
    </b-tab> -->
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue';
import AccountSettingGeneral from './AccountSettingGeneral.vue';
import AccountSettingPassword from './AccountSettingPassword.vue';
import AccountSettingInformation from './AccountSettingInformation.vue';
import ReportSettingInformation from './ReportSettingInformation.vue';
import AccountSettingNotification from './AccountSettingNotification.vue';

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingInformation,
    ReportSettingInformation,
    AccountSettingNotification,
  },
  data() {
    return {
      options: {},
    };
  },
  beforeCreate() {
    this.$http.get('/api/company/settings').then((res) => {
      this.options = res.data;
    });
  },
};
</script>
